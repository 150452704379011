/* eslint-disable import/no-cycle */
import * as CONSTRUCTOR from '../generate';
import * as Fragments from './fragments';

// CREATE_ALLOCATION create credit allocation GraphQL schema.
export const ADD_CREDIT = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'addCredit',
    args: [
      {
        name: 'id', type: 'String', required: true,
      },
      {
        name: 'currency_code', type: 'String', required: true,
      },
      {
        name: 'amount', type: 'String', required: true,
      },
    ],
    argsMap: [
      {
        name: 'id', value: 'id',
      },
      {
        name: 'currency_code', value: 'currency_code',
      },
      {
        name: 'amount', value: 'amount',
      },
    ],
    fields: '{...SubscriptionFields}',
    fragments: [Fragments.Subscription],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// ALLOCATE_ALLOCATION update credit allocation GraphQL schema.
export const ALLOCATE_CREDIT = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'allocateCredit',
    args: [
      {
        name: 'company_id', type: 'String', required: true,
      },
      {
        name: 'from', type: 'String', required: true,
      },
      {
        name: 'to', type: 'String', required: true,
      },
      {
        name: 'currency_code', type: 'String', required: true,
      },
      {
        name: 'amount', type: 'String', required: true,
      },
    ],
    argsMap: [
      {
        name: 'company_id', value: 'company_id',
      },
      {
        name: 'from', value: 'from',
      },
      {
        name: 'to', value: 'to',
      },
      {
        name: 'currency_code', value: 'currency_code',
      },
      {
        name: 'amount', value: 'amount',
      },
    ],
    fields: '{...SubscriptionFields}',
    fragments: [Fragments.Subscription],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// SUBTRACT_ALLOCATION update credit allocation GraphQL schema.
export const SUBTRACT_CREDIT = (props = {}) => {
  const defaultProps = {
    type: 'mutation',
    name: 'subtractCredit',
    args: [
      {
        name: 'id', type: 'String', required: true,
      },
      {
        name: 'company_id', type: 'String', required: true,
      },
      {
        name: 'company_branch_id', type: '[String]', required: true,
      },
      {
        name: 'amount', type: '[String]', required: true,
      },
    ],
    argsMap: [
      {
        name: 'id', value: 'id',
      },
      {
        name: 'company_id', value: 'company_id',
      },
      {
        name: 'company_branch_id', value: 'company_branch_id',
      },
      {
        name: 'amount', value: 'amount',
      },
    ],
    fields: '{...SubscriptionFields}',
    fragments: [Fragments.Subscription],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};
