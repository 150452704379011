/* eslint-disable import/no-cycle */
import {
  CONSTRUCTOR,
} from '..';
import * as Fragments from './fragments';

// Users GraphQL Queries.

// READ user GraphQL schema.
export const READ = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'getUser',
    args: [
      { name: 'id', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'id', value: 'id' },
    ],
    fields: '{...UserFields}',
    fragments: [Fragments.user],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// LIST list users GraphQL schema.
export const LIST = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'listUsers',
    args: [
      { name: 'company_id', type: 'String', required: true },
      { name: 'company_branch_id', type: 'String' },
    ],
    argsMap: [
      { name: 'company_id', value: 'company_id' },
      { name: 'company_branch_id', value: 'company_branch_id' },
    ],
    fields: '{...ListUserResponseFields}',
    fragments: [Fragments.listUserResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// LIST_COMPANY_BRANCH list users GraphQL schema.
export const LIST_COMPANY_BRANCH = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'listCompanyBranches',
    args: [
      { name: 'company_id', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'company_id', value: 'company_id' },
    ],
    fields: '{...ListCompanyBranchesResponseFields}',
    fragments: [Fragments.listCompanyBranchesResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// LIST_USER_GROUPS list users GraphQL schema.
export const LIST_USER_GROUPS = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'listUserGroups',
    args: [
      { name: 'company_id', type: 'String', required: true },
      { name: 'filters', type: '[ConditionInput]' },
      { name: 'sort_latest_update', type: 'Boolean' },
    ],
    argsMap: [
      { name: 'company_id', value: 'company_id' },
      { name: 'filters', value: 'filters' },
      { name: 'sort_latest_update', value: 'sort_latest_update' },
    ],
    fields: '{...ListUserGroupsResponseFields}',
    fragments: [Fragments.listUserGroupsResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// GET_USER_GROUP get user group GraphQL schema.
export const GET_USER_GROUP = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'getUserGroup',
    args: [
      { name: 'id', type: 'String', required: true },
    ],
    argsMap: [
      { name: 'id', value: 'id' },
    ],
    fields: '{...UserGroupFields}',
    fragments: [Fragments.userGroup],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};
