/* eslint-disable import/no-cycle */
import {
  CONSTRUCTOR,
} from '..';
import * as Fragments from './fragments';

// LIST_SUBSCRIPTIONS list subscription GraphQL schema.
export const LIST_SUBSCRIPTIONS = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'listSubscriptions',
    args: [{
      name: 'company_id', type: 'String', required: true,
    }],
    argsMap: [{
      name: 'company_id', value: 'company_id',
    }],
    fields: '{...ListSubscriptionsResponseFields}',
    fragments: [Fragments.listSubscriptionsResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// GET_SUBSCRIPTION get subscription GraphQL schema.
export const GET_SUBSCRIPTION = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'getSubscription',
    args: [{
      name: 'id', type: 'String', required: true,
    }],
    argsMap: [{
      name: 'id', value: 'id',
    }],
    fields: '{...SubscriptionFields}',
    fragments: [Fragments.Subscription],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// GET_SUBSCRIPTION get subscription GraphQL schema.
export const LIST_ACTIVITIES = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'listActivities',
    args: [{
      name: 'id', type: 'String', required: true,
    }],
    argsMap: [{
      name: 'subscription_id', value: 'id',
    }],
    fields: '{...ListActivitiesResponseFields}',
    fragments: [Fragments.listActivitiesResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};

// GET_TOTAL_CREDIT get total credit for company GraphQL schema.
export const GET_TOTAL_CREDIT = (props = {}) => {
  const defaultProps = {
    type: 'query',
    name: 'getTotalCredits',
    args: [{
      name: 'company_id', type: 'String', required: true,
    }],
    argsMap: [{
      name: 'company_id', value: 'company_id',
    }],
    fields: '{...GetTotalCreditsResponseFields}',
    fragments: [Fragments.getTotalCreditsResponse],
  };
  let options = defaultProps;
  if (props) {
    options = Object.assign(defaultProps, props);
  }
  return CONSTRUCTOR.GRAPHQL(options);
};
